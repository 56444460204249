<template>
  <div>
    <div class="row">
      <div class="col-lg-3 mt-4">
        <div v-if="loadingApplications" class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="mb-3">
            <input v-model="searchQuery" type="text" class="form-control" placeholder="Тема, група, або ж ID">
            <select v-model="selectedStatusFilter" class="form-select mt-2">
              <option value="all">Всі статуси</option>
              <option value="true">В роботі</option>
              <option value="false">Не в роботі</option>
              <option value="null">Завершені</option>
            </select>
            <select v-model="selectedCategoryFilter" class="form-select mt-2">
              <option value="all">Всі категорії</option>
              <option v-for="category in categories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </div>

          <div v-if="filteredApplications.length === 0" class="text-center mt-4">
            <p>Нічого не знайдено...</p>
          </div>

          <div v-else class="list-group">
            <div
                v-for="application in paginatedApplications"
                :key="application.id"
                class="list-group-item list-group-item-action"
                :class="{ 'active': application.id === selectedApplication?.id }"
                style="cursor: pointer"
                @click="selectApplication(application)"
            >
              <div class="d-flex justify-content-between">
                <div class="col-lg-10">
                  <div>
                    <small class="mb-1"><b>Тема:</b>{{ application.name }}</small>
                  </div>
                  <div>
                    <small class="mb-1" style="font-size: x-small"><b>Група:</b>
                      <span>
                        {{ getGroupName(application.group_chat_id) }}
                      </span>
                    </small>
                  </div>
                  <div>
                    <span class="mb-1" style="font-size: x-small">{{formatDateTime(application.created_at)}}</span>
                  </div>
                </div>
                <div style="margin-left: 0.5rem">
                  <i v-if="application.in_work == null"
                     class="bi bi-envelope-exclamation-fill m-1"
                     :style="{ color:application.id === selectedApplication?.id ? '#ffffff' : 'rgba(220, 53, 69, 1)' }"
                  ></i>
                  <i v-if="application.in_work == true "
                     class="bi bi-person-workspace m-1"
                     :style="{ color:application.id === selectedApplication?.id ? '#ffffff' : 'rgba(25, 135, 84)' }"
                  ></i>
                  <i v-if="application.in_work == false"
                     class="bi bi-check-circle m-1"
                     :style="{ color:application.id === selectedApplication?.id ? '#ffffff' : 'rgba(25, 135, 84)' }"
                  ></i>
                  <i v-if="0 > 1 "
                     class="bi bi-exclamation-circle m-1"
                     :style="{ color: application.id === selectedApplication?.id ? '#ffffff' : 'rgba(220, 53, 69, 1)' }"
                  ></i>
                </div>
              </div>
            </div>
            <div v-if="filteredApplications.length > 0" class="d-flex justify-content-between align-items-center mt-3">
              <button class="btn btn-outline-primary" :disabled="currentPage === 1" @click="previousPage">Попередня</button>
              <span> {{ currentPage }} з {{ totalPages }}</span>
              <button class="btn btn-outline-primary" :disabled="currentPage === totalPages" @click="nextPage">Наступна</button>
            </div>
          </div>
        </div>
      </div>



      <div class="col-lg-9">
        <div v-if="loadingMessages" class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div v-if="selectedApplication" class="card mt-4">
            <div class="card-body d-flex justify-content-between">
              <!-- Левая часть с темой заявки -->
              <div class="col-lg-10">
                <h5 class="card-title">Тема заявки: <b>{{ selectedApplication.name }}</b></h5>
                <div class="d-flex align-items-center">
                  <div>
                    Тип проблеми:
                  </div>
                  <div v-if="applicationCategory.length" class="d-flex flex-wrap align-items-center" style="margin-left: 0.5rem">
                    <span
                        v-for="category in applicationCategory"
                        :key="category.id"
                        :class="['badge', `text-bg-${category.bg_color}`, 'me-2']"
                        style="cursor: pointer"
                        @click="addCategoryToApplication(category)"
                    >
                      <div class="d-flex align-items-center">
                        {{ category.name }}
                        <div class="hover-effect-delete ms-2" style="cursor: pointer" @click="deletedCategory(category.id)">
                          <i class="bi bi-x-lg"></i>
                        </div>
                      </div>
                    </span>
                    <div class="hover-effect ms-auto" style="margin-left: 0.3rem">
                      <i class="bi bi-plus-circle" style="color: #007bff; cursor: pointer" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="openCategoryModal()"></i>
                    </div>
                  </div>
                  <div v-else class="d-flex align-items-center">
                    <span style="color: orangered;margin-left: 0.3rem" >не обрано</span>
                    <div class="hover-effect" style="margin-left: 0.3rem">
                      <i class="bi bi-plus-circle" style="color: #007bff; cursor: pointer" @click="openCategoryModal()"></i>
                    </div>
                  </div>
                </div>

                <div v-if="changeName" class="input-group mb-3">
                  <input type="text" class="form-control" v-model="newName" aria-label="Recipient's username" aria-describedby="button-addon2">
                  <button class="btn btn-outline-primary" type="button" id="button-addon2" @click="nameChange(selectedApplication.id)">
                    Змінити
                  </button>
                </div>
              </div>




              <div class="col-lg-2 d-flex flex-column align-items-end" v-if="!changeName">
                <button v-if="selectedApplication.in_work == null" class="btn btn-outline-success mb-2 w-100" @click="openWork(selectedApplication.id)">
                  Взяти в роботу
                </button>
                <button v-if="selectedApplication.in_work == true" class="btn btn-outline-danger mb-2 w-100" @click="closedWork(selectedApplication.id)">
                  Завершити чат
                </button>
                <button class="btn btn-outline-primary w-100" @click="goToChangeName()">
                  Змінити тему
                  <i class="bi bi-pencil-square"></i>
                </button>
              </div>
            </div>
          </div>



          <div v-if="selectedApplication" class="card mt-3">
            <div class="card-body">
              <div class="chat-messages" ref="groupMessages">
                <div
                    v-for="message in filteredMessages"
                    :key="message.id"
                    class="message"
                    :class="{ 'from-me': message.from_manager, 'from-them': !message.from_manager }"
                >
                  <div class="message-bubble">
                    <a class="text-muted" style="font-size: small;text-decoration: none">{{ message.sender_name }}</a>
                    <p class="mb-0">{{ message.message }}</p>
                    <a class="text-muted mt-0"
                       style="font-size: small;text-decoration: none">{{ formatDateTime(message.created_at) }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div v-if="selectedApplication" class="card mt-3">
            <div class="card-body">
              <form @submit.prevent="sendMessage">
                <div class="input-group">
                  <input
                      v-model="newMessage"
                      type="text"
                      class="form-control"
                      placeholder="Введіть повідомлення..."
                      required
                  />
                  <div class="btn btn-outline-secondary" @click="selectQuickMessage('Обрати шаблон')">Обрати шаблон</div>
                  <button class="btn btn-primary" type="submit">Надіслати</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div  class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 9999">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Додати категорію</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>
            <div class="d-flex flex-wrap">
              <span
                  v-for="category in categories"
                  :key="category.id"
                  :class="['badge', `text-bg-${category.bg_color}`, 'me-2 hover-effect']"
                  style="cursor: pointer"
                  @click="addCategoryToApplication(category)"
              >
                <div>{{ category.name }}</div>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрити</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createApplication,
  getAllApplications,
  getApplicationById,
  hireApplication,
  closeApplication,
  editApplicationName,
  getGroupMessages,
  getGroupIdByTelegramId,
  sendManagerMessage,
  getAllGroups,
  getAllCategory,
  assignCategoriesToApplication,
  getCategoriesByApplicationId,
  removeCategoryFromApplication
} from '@/js/api';
import group from "./Group.vue";

export default {
  components: {
    group
  },
  data() {
    return {
      applications: [],
      loadingApplications: false,
      selectedApplication: null,
      loadingMessages: false,
      selectedGroupId: null,
      managerId: localStorage.getItem('managerId'),
      currentPage: 1,
      itemsPerPage: 4,
      newMessage: '',
      groupMessages: null,
      changeName: false,
      newName: '',
      groups: [],
      searchQuery: '',
      selectedStatusFilter: 'all',
      selectedCategoryFilter: 'all',
      categoryModal: false,
      categories: [],
      applicationCategory : [],
    };
  },
  async created() {
    await this.fetchApplications();
    await this.fetchGroups();
    await this.fetchCategories();
  },
  computed: {
    filteredMessages() {
      return this.groupMessages;
    },
    filteredApplications() {
      return this.applications
          .filter(application => {
            const groupName = this.getGroupName(application.group_chat_id).toLowerCase();
            const searchText = this.searchQuery.toLowerCase();
            return (
                application.name.toLowerCase().includes(searchText) ||
                groupName.includes(searchText) ||
                application.id.includes(searchText)
            );
          })
          .filter(application => {
            if (this.selectedStatusFilter === 'all') return true;
            return application.in_work === this.selectedStatusFilter;
          })
          .filter(application => {
            if (this.selectedCategoryFilter === 'all') return true;

            if (!application.category || typeof application.category !== 'string') return false;

            let categories = [];
            try {
              categories = JSON.parse(application.category);
            } catch (e) {
              console.error('Error parsing categories:', e);
              return false;
            }


            return Array.isArray(categories) && categories.includes(this.selectedCategoryFilter);
          })
          .sort((a, b) => {
            if (a.in_work === b.in_work) {
              return new Date(b.created_at) - new Date(a.created_at);
            }
            if (a.in_work === 'true') return -1;
            if (b.in_work === 'true') return 1;
            if (a.in_work === 'false') return -1;
            if (b.in_work === 'false') return 1;
            return 0;
          });
    },
    paginatedApplications() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredApplications.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredApplications.length / this.itemsPerPage);
    },
  },
  methods: {
    async fetchApplications() {
      this.loadingApplications = true;
      try {
        const response = await getAllApplications();
        this.applications = response;
        this.loadingApplications = false;
        console.log(this.applications);
      } catch (error) {
        console.error('Failed to load applications:', error);
      } finally {
        this.loadingApplications = false;
      }
    },

    async fetchGroups() {
      try {
        const data = await getAllGroups();
        this.groups = data.data;
      } catch (error) {
        console.error('Failed to load groups:', error);
      }
    },

    async fetchCategories() {
      try {
        this.categories = await getAllCategory();
      } catch (error) {
        console.error('Ошибка при получении категорий:', error);
        throw error;
      }
    },

    async selectApplication(application) {
      this.selectedApplication = application;
      console.log(this.selectedApplication);
      try {
        this.loadingMessages = true;
        await this.fetchGroupMessages(this.selectedApplication.group_chat_id);
        await this.fetchCategoriesByApplication();
        this.scrollToBottom();
      } catch (error) {
        console.error('Failed to load group messages or categories:', error);
      } finally {
        this.loadingMessages = false;
      }
    },

    async fetchGroupMessages(groupId) {
      try {
        const data = await getGroupMessages(groupId);
        this.groupMessages = data.data;
        console.log(this.groupMessages);
        this.scrollToBottom();
      } catch (error) {
        console.error('Failed to load group messages:', error);
      }
    },

    async fetchCategoriesByApplication() {
      const applicationId = this.selectedApplication.id;

      try{
        this.applicationCategory = await getCategoriesByApplicationId(applicationId);
        console.log("category:" , this.applicationCategory);
      }catch (err){
        console.error('Error fetching application categories by id:', err)
      }
    },

    getGroupName(id) {
      const group = this.groups.find(group => group.group_id === id);
      return group ? group.name : 'Група не знайдена';
    },

    async sendMessage() {
      if (!this.newMessage) return;

      try {
        await sendManagerMessage(this.selectedGroupId.groupId, this.newMessage, this.managerId);
        this.newMessage = '';
        console.log(this.selectedGroupId);
        await this.fetchGroupMessages(this.selectedGroupId.groupId);
        this.scrollToBottom();
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    },

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    scrollToBottom() {
      setTimeout(() => {
        const chatMessages = this.$refs.groupMessages;
        if (chatMessages) {
          chatMessages.scrollTop = chatMessages.scrollHeight;
        }
      }, 100);
    },

    formatDateTime(time) {
      const date = new Date(time);
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);

      if (
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
      ) {
        return `Сьогодні, ${date.toLocaleTimeString()}`;
      } else if (
          date.getDate() === yesterday.getDate() &&
          date.getMonth() === yesterday.getMonth() &&
          date.getFullYear() === yesterday.getFullYear()
      ) {
        return `Вчора, ${date.toLocaleTimeString()}`;
      } else {
        return date.toLocaleString();
      }
    },

    async goToChangeName() {
      this.changeName = !this.changeName;
      this.newName = this.selectedApplication.name;
    },

    async nameChange(applicationId) {
      try {
        await editApplicationName(applicationId, this.newName);
        await this.fetchApplications();
        this.selectedApplication.name = this.newName;
      } catch (error) {
        console.error('Error changing application name:', error);
      } finally {
        this.changeName = !this.changeName;
      }
    },

    async openWork(id) {
      try {
        const managerId = localStorage.getItem('token');
        await hireApplication(id , managerId);
        this.selectedApplication.in_work = true;
      } catch (error) {
        console.error('Error updating work status for application.', error);
      }
    },

    async closedWork(id) {
      try {
        await closeApplication(id);
        this.selectedApplication.in_work = false;
      } catch (error) {
        console.error('Error closing work application.', error);
      }
    },

    openCategoryModal() {
      const modalElement = document.getElementById('categoryModal');
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    },

    async addCategoryToApplication(category) {
      const applicationId = this.selectedApplication.id;
      const categoryIds = [category.id];
      try {
        await assignCategoriesToApplication(applicationId, categoryIds);
        await this.fetchCategoriesByApplication();
      } catch (err) {
        console.error('Error adding category to application:', err);
      }
    },
    async deletedCategory(categoryId) {
      const applicationId = this.selectedApplication.id;
      try {
        await removeCategoryFromApplication(applicationId, categoryId);
        await this.fetchCategoriesByApplication();
      } catch (err) {
        console.error('Error deleted category from application;', err)
      }
    }
  },
};
</script>

<style scoped>
.list-group-item.active {
  background-color: #007bff;
  color: white;
}

.card {
  margin-top: 1rem;
}

.chat-messages {
  max-height: 400px;
  overflow-y: auto;
}

.message {
  display: flex;
  margin-bottom: 10px;
}

.message-bubble {
  padding: 10px;
  border-radius: 15px;
  max-width: 70%;
}

.from-me {
  justify-content: flex-end;
}

.from-me .message-bubble {
  background-color: #007bff;
  color: white;
  text-align: right;
}

.from-them {
  justify-content: flex-start;
}

.from-them .message-bubble {
  background-color: #f1f1f1;
  color: black;
  text-align: left;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.hover-effect:hover {
  color: #0056b3;
  transform: scale(1.2);
  transition: color 0.3s, transform 0.3s;
}

.hover-effect-delete:hover {
  color: #f1f1f1;
  transform: scale(1.4);
  transition: color 0.3s, transform 0.3s;
  font-weight: 900;
}
</style>
