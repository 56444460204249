<template>
  <Navbar/>
  <div class="col-lg-12 p-4 mt-4">


    <div class="d-flex justify-content-between align-items-center col-lg-12 mt-4">
      <h1>Менеджери</h1>
      <button class="btn btn-outline-primary" @click="openAddModal">Додати менеджера</button>
    </div>


    <div class="table-responsive">
      <table class="table table-hover table-striped table-bordered mt-4 align-middle">
        <thead style="background-color: gray">
        <tr class="text-center">
          <th>Імʼя</th>
          <th>Фамілія</th>
          <th>Номер телефону</th>
          <th>Telegram ID</th>
          <th>Тип</th>
          <th class="text-center">Дії</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="manager in managers" :key="manager.id" class="text-center">
          <td>{{ manager.name }}</td>
          <td>{{ manager.surname }}</td>
          <td>{{ manager.phone }}</td>
          <td>{{ manager.telegram_id }}</td>
          <td class="text-center">
            <span :class="{'badge bg-primary': manager.type === 'superuser', 'badge bg-secondary': manager.type === 'user'}">
              {{ manager.type === 'superuser' ? 'Superuser' : 'User' }}
            </span>
          </td>
          <td>
            <button class="btn btn-sm btn btn-outline-primary" @click="openEditModal(manager)">
              <i class="bi bi-pencil-square"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>


    <div class="modal fade" id="managerModal" tabindex="-1" aria-labelledby="managerModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="managerModalLabel">{{ editMode ? 'Редагування профіля' : 'Додати менеджера' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="saveManager">
              <div class="mb-3">
                <label class="form-label">Імʼя</label>
                <input v-model="managerForm.name" type="text" class="form-control" required />
              </div>
              <div class="mb-3">
                <label class="form-label">Фамілія</label>
                <input v-model="managerForm.surname" type="text" class="form-control" required />
              </div>
              <div class="mb-3">
                <label class="form-label">Телефон</label>
                <input v-model="managerForm.phone" type="text" class="form-control" placeholder="+380XXXXXXXXX" required />
              </div>
              <div class="mb-3">
                <label class="form-label">Telegram ID</label>
                <input v-model="managerForm.telegram_id" type="text" class="form-control" required />
                <small class="form-label">Можна отримати в <b>@getmyid_bot</b></small>
              </div>
              <div class="mb-3">
                <label class="form-label">Тип</label>
                <select v-model="managerForm.type" class="form-select">
                  <option value="user">User</option>
                  <option value="superuser">Superuser</option>
                </select>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-primary">{{ editMode ? 'Зберегти зміни' : 'Додати' }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { getManagers, addManager, updateManager } from '@/js/api';

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      managers: [],
      managerForm: {
        id: null,
        name: '',
        surname: '',
        phone: '',
        telegram_id: '',
        type: 'user',
      },
      editMode: false,
    };
  },
  async created() {
    await this.fetchManagers();
  },
  methods: {
    async fetchManagers() {
      try {
        this.managers = await getManagers();
      } catch (error) {
        console.error('Error get managers list:', error);
      }
    },
    async saveManager() {
      try {
        if (this.editMode) {
          await updateManager(this.managerForm);
        } else {
          await addManager(this.managerForm);
        }
        this.resetForm();
        this.closeModal();
        await this.fetchManagers();
      } catch (error) {
        console.error('Error save manager:', error);
      }
    },
    openAddModal() {
      this.resetForm();
      this.editMode = false;
      const modal = new bootstrap.Modal(document.getElementById('managerModal'));
      modal.show();
    },
    openEditModal(manager) {
      this.managerForm = {...manager};
      this.editMode = true;
      const modal = new bootstrap.Modal(document.getElementById('managerModal'));
      modal.show();
    },
    closeModal() {
      const modal = bootstrap.Modal.getInstance(document.getElementById('managerModal'));
      if (modal) modal.hide();
    },
    resetForm() {
      this.managerForm = {
        id: null,
        name: '',
        surname: '',
        phone: '',
        telegram_id: '',
        type: 'user',
      };
      this.editMode = false;
    },
  },
};
</script>

<style scoped>
.table {
  margin-top: 1rem;
}
.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}
.badge {
  padding: 8px 12px;
  font-size: 14px;
}
</style>
