<template>
  <div id="app" class="col-lg-12">
    <router-view/>

    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <i class="bi bi-send-plus me-2"></i>
          <strong class="me-auto">Нова заявка</strong>
<!--          <small>11 mins ago</small>-->
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ toastMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      toastTitle: "",
      toastMessage: "",
      toastApplicationId: null,
    };
  },
  async created() {
    this.connectToWebSocket();
  },
  methods: {
    connectToWebSocket() {
      const socket = new WebSocket("ws://localhost:3000");
      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);

        if (message.type === "new_application") {
          this.toastTitle = "Нова заявка";
          this.toastMessage = message.application_name;
          this.toastApplicationId = message.application_id;
          this.showToast();
        }
      };
    },
    showToast() {
      this.$nextTick(() => {
        const toastEl = document.getElementById("liveToast");
        if (toastEl) {
          toastEl.setAttribute("data-bs-delay", "3000");
          const toast = new bootstrap.Toast(toastEl, { animation: true });
          toast.show();
        }
      });
    },
  },
};
</script>

<style scoped>
#liveToast {
  z-index: 1050;
}
</style>
