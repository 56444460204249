import axios from 'axios';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;


export async function requestLoginCode(phone) {
    try {
        await axios.post(`${API_BASE_URL}/managers/send-code`, { phone });
    } catch (error) {
        throw new Error('Error get code');
    }
}

export async function loginManager(phone, verificationCode) {
    try {
        const response = await axios.post(`${API_BASE_URL}/managers/verify-code`, { phone, verificationCode });
        return response.data;
    } catch (error) {
        throw new Error('Error authentication');
    }
}

export async function getAllGroups() {
    try {
        const response = await axios.get(`${API_BASE_URL}/group/get-all`);
        return response.data;
    } catch (error) {
        console.error('Error get groups list:', error);
        throw error;
    }
}

export async function getGroupMessages(groupId) {
    try {
        const response = await axios.get(`${API_BASE_URL}/group/get-message/${groupId}`);
        return response.data;
    } catch (error) {
        console.error('Error get group massages:', error);
        throw error;
    }
}

export async function getGroupIdByTelegramId (telegramId){
    try {
        const response = await axios.get(`${API_BASE_URL}/api/groups/getIdById/${telegramId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching group ID by Telegram ID:', error);
        throw error;
    }
};


export async function sendManagerMessage(groupId, message, managerId) {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/groups/send-manager-message`, {
            groupId,
            message,
            managerId
        });
        return response.data;
    } catch (error) {
        console.error('Error send manager massage:', error);
        throw error;
    }
}

export async function getManagers() {
    try {
        const response = await axios.get(`${API_BASE_URL}/managers/get-all`);
        return response.data;
    } catch (error) {
        throw new Error('Error get managers list');
    }
}

export async function addManager(manager) {
    try {
        const response = await axios.post(`${API_BASE_URL}/managers/add`, manager);
        return response.data;
    } catch (error) {
        throw new Error('Error add manager');
    }
}
export async function updateManager(updateData) {
    try {
        const response = await axios.put(`${API_BASE_URL}/managers/update/${updateData.id}`, updateData);
        return response.data;
    } catch (error) {
        throw new Error('Error update manager');
    }
}

export async function setMainManager(groupId, managerId) {
    try {
        const response = await axios.patch(`${API_BASE_URL}/group/edit-main-manager/${groupId}`, { managerId });
        return response.data;
    } catch (error) {
        console.error('Error setting main manager:', error);
        throw error;
    }
}


export async function createFolder(folderName) {
    try {
        const response = await axios.post(`${API_BASE_URL}/templates/folders/create`, {
            name: folderName,
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при создании папки:', error);
        throw error;
    }
}


export async function editFolder(folderId, newName) {
    try {
        const response = await axios.put(`${API_BASE_URL}/templates/folders/edit/${folderId}`, {
            name: newName,
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при редактировании папки:', error);
        throw error;
    }
}


export async function deleteFolder(folderId) {
    try {
        const response = await axios.delete(`${API_BASE_URL}/templates/folders/delete/${folderId}`);
        return response.data;
    } catch (error) {
        console.error('Ошибка при удалении папки:', error);
        throw error;
    }
}


export async function getAllFolders() {
    try {
        const response = await axios.get(`${API_BASE_URL}/templates/folders/get-all`);
        return response.data;
    } catch (error) {
        console.error('Ошибка при получении папок:', error);
        throw error;
    }
}


export async function createTemplate(folder_id, templateContent) {
    try {
        const response = await axios.post(`${API_BASE_URL}/templates/create`, {
            folderId: folder_id,
            template: templateContent,
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при создании шаблона:', error);
        throw error;
    }
}


export async function editTemplate(templateId, folderId, templateContent) {
    try {
        const response = await axios.put(`${API_BASE_URL}/templates/edit/${templateId}`, {
            folder: folderId,
            template: templateContent,
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при редактировании шаблона:', error);
        throw error;
    }
}


export async function deleteTemplate(templateId) {
    try {
        const response = await axios.delete(`${API_BASE_URL}/templates/delete/${templateId}`);
        return response.data;
    } catch (error) {
        console.error('Ошибка при удалении шаблона:', error);
        throw error;
    }
}


export async function getAllTemplates() {
    try {
        const response = await axios.get(`${API_BASE_URL}/templates/get-all`);
        return response.data;
    } catch (error) {
        console.error('Ошибка при получении шаблонов:', error);
        throw error;
    }
}

export async function getManagerMessages (managerId) {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/managers/get-managers/massage/${managerId}`);
        return response.data;
    } catch (error) {
        console.error('Ошибка при получении шаблонов:', error);
        throw error;
    }
}
export async function analyzeManagerMessages(messages) {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/analyze-manager-messages`, {
            messages,
        });
        return response;
    } catch (error) {
        console.error('Ошибка при анализе сообщений:', error);
        throw error;
    }
}

export const createApplication = async (clientId, groupId, message) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/applications`, { clientId, groupId, message });
        return response.data;
    } catch (error) {
        console.error('Error creating application:', error);
        throw error;
    }
};


export const getAllApplications = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/applications`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all applications:', error);
        throw error;
    }
};


export const getApplicationById = async (applicationId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/applications/${applicationId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching application by ID ${applicationId}:`, error);
        throw error;
    }
};


export const hireApplication = async (applicationId, managerId) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/applications/${applicationId}/hire/${managerId}`);
        return response.data;
    } catch (error) {
        console.error(`Error updating application to hired with ID ${applicationId}:`, error);
        throw error;
    }
};


export const closeApplication = async (applicationId) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/applications/${applicationId}/close`);
        return response.data;
    } catch (error) {
        console.error(`Error updating application to closed with ID ${applicationId}:`, error);
        throw error;
    }
};


export const editApplicationName = async (applicationId, newName) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/applications/${applicationId}/edit`, { newName });
        return response.data;
    } catch (error) {
        console.error(`Error updating application name with ID ${applicationId}:`, error);
        throw error;
    }
};

export async function getApplicationInWorkFalse() {
    try {
        console.log('Requesting application count...');

        const response = await axios.get(`${API_BASE_URL}/applications/not-work`);

        console.log('Response:', response.data);

        return response.data;
    } catch (error) {
        console.error('Error fetching application count:', error);
        throw error;
    }
}

export const createCategory = async (name, bg_color) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/category`, {
            name: name,
            bg_color: bg_color,
        });
        return response.data;
    } catch (error) {
        console.error('Error create category:', error);
        throw error;
    }
};


export const getAllCategory = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/category/all`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all category:', error);
        throw error;
    }
};


export const editCategory = async (categoryId, newName, bg_color) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/category/edit/${categoryId}`, {
            newName, bg_color
        });
        return response.data;
    } catch (error) {
        console.error(`Error updating category name with ID ${categoryId}:`, error);
        throw error;
    }
};


export async function deleteCategory(categoryId) {
    try {
        const response = await axios.delete(`${API_BASE_URL}/category/delete/${categoryId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleted category:', error);
        throw error;
    }
}

export const assignCategoriesToApplication = async (applicationId, categoryIds) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/applications/${applicationId}/categories`, {
            categoryIds
        });
        return response.data;
    } catch (error) {
        console.error('Error assigning categories to application:', error);
        throw error;
    }
};

export const getCategoriesByApplicationId = async (applicationId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/applications/${applicationId}/categories`);
        return response.data;
    } catch (error) {
        console.error('Error fetching categories for application:', error);
        throw error;
    }
};

export const removeCategoryFromApplication = async (applicationId, categoryId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/applications/${applicationId}/categories/${categoryId}`);
        return response.data;
    } catch (error) {
        console.error('Error removing category from application:', error);
        throw error;
    }
};





