<template>
  <div class="d-flex justify-content-center align-items-center vh-100" style="background-color: gray">
    <div class="container p-5" style="border: 1px solid gray;border-radius: 30px;background-color: #f1f1f1">
      <div class="text-center">
        <h3>Вхід</h3>
      </div>
      <form @submit.prevent="requestCode" v-if="step2 === false">
        <div class="mb-3">
          <div class="text-center">
            <label for="phone" class="form-label">Введіть свій номер телефону</label>
          </div>
          <input
              v-model="phone"
              type="text"
              class="form-control text-center"
              id="phone"
              placeholder="+380XXXXXXXXX"
              required
          />
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-primary text-center">Отримати код</button>
        </div>
      </form>

      <div v-if="showCodeForm" class="mt-4">
        <form @submit.prevent="login">
          <div class="mb-3">
            <div class="text-center">
              <label for="code" class="form-label">Введіть код пітвердежння, який був надісланий в бот за цим номером <b>{{phone}}</b></label>
            </div>
            <input
                v-model="code"
                type="text"
                class="form-control text-center"
                id="code"
                placeholder="Введіть код"
                required
            />
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-primary">Увійти</button>
          </div>
        </form>
      </div>

      <div v-if="errorMessage" class="alert alert-danger mt-3">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { requestLoginCode, loginManager } from '@/js/api';

export default {
  data() {
    return {
      phone: '',
      code: '',
      showCodeForm: false,
      errorMessage: '',
      step2:false,
    };
  },
  methods: {
    async requestCode() {
      try {
        await requestLoginCode(this.phone);
        this.showCodeForm = true;
        this.step2 = true;
      } catch (error) {
        this.errorMessage = 'Помилка при запросі коду. Перевірте номер телефону.';
      }
    },
    async login() {
      try {
        const response = await loginManager(this.phone, this.code);
        console.log('Ответ от сервера:', response);


        if (response.token && manager) {
          localStorage.setItem('authToken', response.token);
          localStorage.setItem('managerId', manager.id);
          localStorage.setItem('managerType', manager.type);
          this.$router.push('/');
        } else {
          throw new Error('Не удалось получить данные менеджера или токен');
        }
      } catch (error) {
        console.error('Ошибка входа:', error);
        this.errorMessage = 'Невірний код пітвердження.';
      }
    }
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
}

.vh-100 {
  height: 100vh;
}

.container {
  max-width: 400px;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}
</style>
