<template>
  <Navbar />
  <div class="container mt-4">
    <div class="row">
      <div class="col-lg-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h4>Папки</h4>
          <button class="btn btn-success btn-sm" @click="openAddFolderModal">Додати папку</button>
        </div>
        <div class="list-group">
          <div v-if="folders.length === 0" class="text-center text-muted">
            Папок ще немає.
          </div>
          <div
              v-for="folder in folders"
              :key="folder.id"
              class="list-group-item list-group-item-action"
              :class="{ 'active': folder.id === selectedFolder?.id }"
          >
            <div class="d-flex">
              <div class="col-lg-9" @click="selectFolder(folder)">
                <span>{{ folder.name }}</span>
              </div>
              <div class="d-flex col-lg-3">
                <button class="btn btn-sm btn-outline-primary me-2" @click="openEditFolderModal(folder)"><i class="bi bi-pencil-square"></i></button>
                <button class="btn btn-sm btn-outline-danger" @click="deleteFolder(folder.id)"><i class="bi bi-trash3"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div v-if="selectedFolder" class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5>Шаблони в {{ selectedFolder.name }}</h5>
            <button class="btn btn-primary btn-sm" @click="openAddTemplateModal">Додати шаблон</button>
          </div>
          <div class="card-body">
            <div v-if="filteredTemplates.length === 0" class="text-center text-muted">
              Немає створених шаблонів.
            </div>
            <ul class="list-group">
              <li v-for="template in filteredTemplates" :key="template.id" class="list-group-item d-flex justify-content-between">
                <span>{{ template.template }}</span>
                <div>
                  <button class="btn btn-sm btn-outline-primary me-2" @click="openEditTemplateModal(template)">Редагувати</button>
                  <button class="btn btn-sm btn-outline-danger" @click="deleteTemplate(template.id)">Видалити</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-4 mt-4">
        <div class="d-flex align-items-center mb-3">
          <h4>Категорії</h4>
          <button class="btn btn-success" style="margin-left: 1rem" @click="openAddCategoryModal">Додати</button>
        </div>
        <div class="list-group col-lg-9">
          <div v-if="categories.length === 0" class="text-center text-muted">
            Категорій ще немає.
          </div>
          <div
              v-for="category in categories"
              :key="category.id"
              class="list-group-item list-group-item-action"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span class="badge text-bg-primary me-1" v-if="category.bg_color === 'primary'">{{ category.name }}</span>
              <span class="badge text-bg-secondary me-1" v-if="category.bg_color === 'secondary'">{{ category.name }}</span>
              <span class="badge text-bg-success me-1" v-if="category.bg_color === 'success'">{{ category.name }}</span>
              <span class="badge text-bg-danger me-1" v-if="category.bg_color === 'danger'">{{ category.name }}</span>
              <span class="badge text-bg-warning me-1" v-if="category.bg_color === 'warning'">{{ category.name }}</span>
              <span class="badge text-bg-info me-1" v-if="category.bg_color === 'info'">{{ category.name }}</span>
              <span class="badge text-bg-light me-1" v-if="category.bg_color === 'light'">{{ category.name }}</span>
              <span class="badge text-bg-dark me-1" v-if="category.bg_color === 'dark'">{{ category.name }}</span>
              <div>
                <button class="btn btn-sm btn-outline-primary me-2" @click="openEditCategoryModal(category)"><i class="bi bi-pencil-square"></i></button>
                <button class="btn btn-sm btn-outline-danger" @click="deleteCategory(category.id)"><i class="bi bi-trash3"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals for Folders, Templates, and Categories -->

    <!-- Add Folder Modal -->
    <div class="modal fade" id="folderModal" tabindex="-1" role="dialog" aria-labelledby="folderModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="folderModalLabel">Додати папку</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="folderName" class="form-label">Назва папки</label>
              <input type="text" class="form-control" id="folderName" v-model="folderName" required />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрити</button>
            <button type="button" class="btn btn-primary" @click="addFolder">Додати папку</button>
          </div>
        </div>
      </div>
    </div>




    <div class="modal fade" id="templateModal" tabindex="-1" role="dialog" aria-labelledby="templateModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="templateModalLabel">Додати шаблон</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="templateContent" class="form-label">Шаблон</label>
              <textarea class="form-control" id="templateContent" rows="3" v-model="newTemplateText"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрити</button>
            <button v-if="editTemplate = null" type="button" class="btn btn-primary" @click="saveTemplate">Редагувати шаблон</button>
            <button v-else type="button" class="btn btn-primary" @click="saveTemplate">Додати шаблон</button>
          </div>
        </div>
      </div>
    </div>




    <div class="modal fade" id="categoryModal" tabindex="-1" role="dialog" aria-labelledby="categoryModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="categoryModalLabel">Додати категорію</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="categoryName" class="form-label">Назва категорії</label>
              <input type="text" class="form-control" id="categoryName" v-model="categoryName" required />
            </div>
            <div class="mb-3">
              <label for="categoryColor" class="form-label">Колір фону</label>
              <select class="form-control" id="categoryColor" v-model="categoryColor">
                <option value="primary">Синій</option>
                <option value="secondary">Сірий</option>
                <option value="success">Зелений</option>
                <option value="danger">Червоний</option>
                <option value="warning">Жовтий</option>
                <option value="info">Голубий</option>
                <option value="light">Білий</option>
                <option value="dark">Чорний</option>
              </select>
            </div>
            <div class="col-lg-12">
              <h5>Приклади кольорів</h5>
              <span class="badge text-bg-primary me-1">Синій</span>
              <span class="badge text-bg-secondary me-1">Сірий</span>
              <span class="badge text-bg-success me-1">Зелений</span>
              <span class="badge text-bg-danger me-1">Червоний</span>
              <span class="badge text-bg-warning me-1">Жовтий</span>
              <span class="badge text-bg-info me-1">Голубий</span>
              <span class="badge text-bg-light me-1">Білий</span>
              <span class="badge text-bg-dark me-1">Чорний</span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрити</button>
            <button type="button" class="btn btn-primary" @click="addCategory">Додати категорію</button>
          </div>
        </div>
      </div>
    </div>




    <div class="modal fade" id="editCategoryModal" tabindex="-1" role="dialog" aria-labelledby="editCategoryModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editCategoryModalLabel">Редагувати категорію</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="editCategoryName" class="form-label">Нова назва категорії</label>
              <input type="text" class="form-control" id="editCategoryName" v-model="editCategoryName" required />
            </div>
            <div class="mb-3">
              <label for="editCategoryColor" class="form-label">Колір фону</label>
              <select class="form-control" id="categoryColor" v-model="editCategoryColor">
                <option value="primary">Синій</option>
                <option value="secondary">Сірий</option>
                <option value="success">Зелений</option>
                <option value="danger">Червоний</option>
                <option value="warning">Жовтий</option>
                <option value="info">Голубий</option>
                <option value="light">Білий</option>
                <option value="dark">Чорний</option>
              </select>
            </div>
            <div class="col-lg-12">
              <h5>Приклади кольорів</h5>
              <span class="badge text-bg-primary me-1">Синій</span>
              <span class="badge text-bg-secondary me-1">Сірий</span>
              <span class="badge text-bg-success me-1">Зелений</span>
              <span class="badge text-bg-danger me-1">Червоний</span>
              <span class="badge text-bg-warning me-1">Жовтий</span>
              <span class="badge text-bg-info me-1">Голубий</span>
              <span class="badge text-bg-light me-1">Білий</span>
              <span class="badge text-bg-dark me-1">Чорний</span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрити</button>
            <button type="button" class="btn btn-primary" @click="editCategoryHandler">Зберегти зміни</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import {
  getAllFolders,
  createFolder,
  editFolder,
  deleteFolder,
  getAllTemplates,
  createTemplate,
  editTemplate,
  deleteTemplate,
  getAllCategory,
  createCategory,
  editCategory,
  deleteCategory,
} from "@/js/api";

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      folders: [],
      selectedFolder: null,
      templates: [],
      folderName: '',
      newFolderName: '',
      templateForm: { id: null, content: "" },
      filteredTemplates: [],
      editFolder: {},
      newTemplateText: '',
      editTemplate: {},
      changeNewFolder: false,
      newIdFolder: null,
      categories: [],
      categoryName: '',
      categoryColor: 'success',
      editCategory: null,
      editCategoryColor: '',
      editCategoryName: '',
    };
  },
  async created() {
    await this.fetchFolders();
    await this.fetchCategories();
  },
  methods: {
    async fetchFolders() {
      try {
        this.folders = await getAllFolders();
        console.log(this.folders);
      } catch (error) {
        console.error('Ошибка при получении папок:', error);
        throw error;
      }
    },
    async fetchCategories() {
      try {
        this.categories = await getAllCategory();
        console.log(this.categories);
      } catch (error) {
        console.error('Ошибка при получении категорий:', error);
        throw error;
      }
    },
    async addCategory() {
      try {
        await createCategory(this.categoryName, this.categoryColor);
        this.categoryName = '';
        this.categoryColor = 'success';
        await this.fetchCategories();
        const modal = bootstrap.Modal.getInstance(document.getElementById('categoryModal'));
        if (modal) modal.hide();
      } catch (error) {
        console.error('Ошибка при добавлении категории:', error);
        throw error;
      }
    },
    openEditCategoryModal(category) {
      this.editCategory = category;
      this.editCategoryColor = category.bg_color;
      this.editCategoryName = category.name;
      new bootstrap.Modal(document.getElementById('editCategoryModal')).show();
    },
    async editCategoryHandler() {
      try {
        await editCategory(this.editCategory.id, this.editCategoryName, this.editCategoryColor);
        await this.fetchCategories();
        const modal = bootstrap.Modal.getInstance(document.getElementById('editCategoryModal'));
        if (modal) modal.hide();
      } catch (error) {
        console.error('Ошибка при редактировании категории:', error);
        throw error;
      }
    },
    async deleteCategory(categoryId) {
      try {
        await deleteCategory(categoryId);
        await this.fetchCategories();
      } catch (error) {
        console.error('Ошибка при удалении категории:', error);
        throw error;
      }
    },
    openAddCategoryModal() {
      new bootstrap.Modal(document.getElementById('categoryModal')).show();
    },
    async selectFolder(folder) {
      this.selectedFolder = folder;
      await this.fetchTemplates();
      this.filteredTemplates = this.templates.filter(template => template.folder.id === folder.id);
    },
    async fetchTemplates() {
      try {
        this.templates = await getAllTemplates();
      } catch (error) {
        console.error('Ошибка при получении шаблонов:', error);
        throw error;
      }
    },
    openAddFolderModal() {
      new bootstrap.Modal(document.getElementById('folderModal')).show();
    },
    async addFolder() {
      try {
        await createFolder(this.folderName);
        this.folderName = '';
        await this.fetchFolders();
        const modal = bootstrap.Modal.getInstance(document.getElementById('folderModal'));
        if (modal) modal.hide();
      } catch (error) {
        console.error('Ошибка при сохранении папки:', error);
        throw error;
      }
    },
    openAddTemplateModal() {
      new bootstrap.Modal(document.getElementById("templateModal")).show();
    },
    async saveTemplate() {
      try {
        if (this.editTemplate && this.editTemplate.id) {
          await editTemplate(this.editTemplate.id, this.selectedFolder.id, this.newTemplateText);
        } else {
          await createTemplate(this.selectedFolder.id, this.newTemplateText);
        }
        await this.fetchTemplates();
        await this.selectFolder(this.selectedFolder);
        const modal = bootstrap.Modal.getInstance(document.getElementById('templateModal'));
        if (modal) modal.hide();
      } catch (error) {
        console.error('Ошибка при сохранении шаблона:', error);
        throw error;
      }
    },

    openEditTemplateModal(template) {
      this.editTemplate = template;
      this.newTemplateText = template.template;
      new bootstrap.Modal(document.getElementById('templateModal')).show();
    },
    async deleteTemplate(templateId) {
      try {
        await deleteTemplate(templateId);
        await this.fetchTemplates();
        if (this.selectedFolder) {
          await this.selectFolder(this.selectedFolder);
        }
      } catch (error) {
        console.error('Ошибка при удалении шаблона:', error);
        throw error;
      }
    },
  },
};
</script>

<style scoped>
.list-group-item.active {
  background-color: #007bff;
  color: white;
}
</style>
