<template>
  <Navbar />
  <div class="container mt-4">
    <div class="row">
      <div class="col-lg-12">
        <h4>Експортувати повідомлення менеджера</h4>
        <div class="mb-3">
          <label for="managerSelect" class="form-label">Оберіть менеджера</label>
          <select id="managerSelect" v-model="selectedManagerId" class="form-select">
            <option v-for="manager in managers" :key="manager.id" :value="manager.id">
              {{ manager.name }}
            </option>
          </select>
        </div>
        <button class="btn btn-primary" @click="exportMessages">Викачати повідомлення в EXCEL</button>
        <button class="btn btn-warning ms-3" @click="analyzeMessages">Проаналізувати відповіді</button>
      </div>
    </div>
  </div>

  <div id="loadingModal" class="modal" tabindex="-1" v-if="loading">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Зачекайте</h5>
        </div>
        <div class="modal-body text-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p class="mt-3">Отримуємо відповідь від ChatGPT...</p>
        </div>
      </div>
    </div>
  </div>

  <div id="analysisModal" class="modal" tabindex="-1" v-if="showAnalysis">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Результат аналізу</h5>
          <button type="button" class="btn-close" @click="closeAnalysisModal"></button>
        </div>
        <div class="modal-body">
          <div v-html="analysisResult"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeAnalysisModal">Закрити</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { getManagers, getManagerMessages, analyzeManagerMessages } from "@/js/api";
import * as XLSX from 'xlsx';

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      managers: [],
      selectedManagerId: null,
      loading: false,
      showAnalysis: false,
      analysisResult: '',
    };
  },
  async created() {
    await this.fetchManagers();
  },
  methods: {
    async fetchManagers() {
      try {
        this.managers = await getManagers();
      } catch (error) {
        console.error("Ошибка при получении списка менеджеров:", error);
      }
    },
    async exportMessages() {
      if (!this.selectedManagerId) {
        alert('Пожалуйста, выберите менеджера.');
        return;
      }
      try {
        const messages = await getManagerMessages(this.selectedManagerId);
        if (!messages.length) {
          alert('Сообщения не найдены для выбранного менеджера.');
          return;
        }

        const worksheet = XLSX.utils.json_to_sheet(messages);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Сообщения менеджера');

        XLSX.writeFile(workbook, `messages_manager_${this.selectedManagerId}.xlsx`);
      } catch (error) {
        console.error('Ошибка при экспорте сообщений:', error);
      }
    },
    async analyzeMessages() {
      if (!this.selectedManagerId) {
        alert('Пожалуйста, выберите менеджера.');
        return;
      }
      try {
        this.loading = true;
        const messages = await getManagerMessages(this.selectedManagerId);
        if (!messages.length) {
          alert('Сообщения не найдены для выбранного менеджера.');
          this.loading = false;
          return;
        }

        const analysis = await analyzeManagerMessages(messages);
        this.analysisResult = analysis.data.analysis;
        this.showAnalysis = true;
      } catch (error) {
        console.error('Ошибка при анализе сообщений:', error);
      } finally {
        this.loading = false;
      }
    },
    closeAnalysisModal() {
      this.showAnalysis = false;
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
}
.modal {
  display: block;
  background: rgba(0, 0, 0, 0.5);
}
.modal-dialog {
  max-width: 500px;
  margin: 100px auto;
}
</style>
