<template>
  <Navbar />
  <div class="container mt-4">
    <div class="row">
      <div class="col-lg-12 d-flex mb-1">
        <button
            type="button"
            class="btn btn-primary"
            @click="activeComponent = 'groups'"
            :class="{ active: activeComponent === 'groups' }"
        >
          Групи
        </button>
        <button
            type="button"
            class="btn btn-primary ms-3"
            @click="activeComponent = 'chats'"
            :class="{ active: activeComponent === 'chats' }"
        >
          Чати
        </button>
        <button
            type="button"
            class="btn btn-primary ms-3 position-relative"
            @click="activeComponent = 'applications'"
            :class="{ active: activeComponent === 'applications' }"
        >
          Заявки
          <span
              v-if="inWorkFalseCount !== null"
              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
          >
            {{ inWorkFalseCount }}
            <span class="visually-hidden">unread messages</span>
          </span>
        </button>
      </div>
    </div>

    <div class="row">
      <div v-if="activeComponent === 'groups'">
        <GroupsComponent ref="groupsComponent" />
      </div>
      <div v-if="activeComponent === 'chats'">
        <ChatsComponent />
      </div>
      <div v-if="activeComponent === 'applications'">
        <ApplicationsComponent/>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import GroupsComponent from "@/views/Pages/WorkTool/Group.vue";
import ChatsComponent from "@/views/Pages/WorkTool/Chat.vue";
import ApplicationsComponent from "@/views/Pages/WorkTool/Application.vue";
import { getApplicationInWorkFalse } from "@/js/api";

export default {
  components: {
    Navbar,
    GroupsComponent,
    ChatsComponent,
    ApplicationsComponent,
  },
  data() {
    return {
      activeComponent: "groups",
      inWorkFalseCount: null,
    };
  },
  methods: {
    async fetchInWorkFalseCount() {
      try {
        const response = await getApplicationInWorkFalse();
        console.log(response);
        this.inWorkFalseCount = response;
      } catch (error) {
        console.error("Failed to fetch count of applications with in_work false:", error);
      }
    },
  },
};
</script>
